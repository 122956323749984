.ready-begin{
    justify-content: space-between;
    margin-bottom: 150px !important;
    &_info{
        max-width: 360px;
    }

    &_case{
        display: grid; 
        grid-template-columns: 1fr 1fr; 
        grid-template-rows: 1fr; 
        gap: 0px 20px; 
    }


}

.box-info0{
    &_title0{
        max-width: 158px;
    }
    &_text1{
        margin: 46px 0 0;
    }
}

@media (max-width:920px){
    .ready-begin{

        flex-direction: column !important;

        &_info{
            max-width: 540px;
        }
    }
    
}

@media (max-width:920px){
    .ready-begin{
        margin-top: 40px !important;
        &_case{
            grid-template-columns: 1fr; 
            grid-template-rows: 1fr 1fr; 
            gap: 40px 20px; 
        }
    }
    
}