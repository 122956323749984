.icon{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;

}

.icon-case{
   
    max-width: 217px;
    margin: 50px auto 25px;
    &_img{

        position: relative;
        width: 100px;
        height: 100px;
        background: #4e305710;
        border-radius:30px ;
        z-index: 0;
        &>img{
            position: relative;
            z-index: 1;
        }
    }

    &_text{
        display: flex;
        justify-content: center;
        font-size: 20px;
        line-height: 1.55;
        font-weight: 600;
        color: #000000;
        margin: 20px 0 0;
    }
}

@media (max-width:800px) {
    .icon{
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(4, 1fr);   
    }
}
@media (max-width:480px) {
    .icon {
        grid-template-rows: repeat(4, 0fr);
    }

    .icon-case{
        margin: 25px auto 25px;
        &_text{
            font-size: 18px;
            text-align: center;
        }
    }
}