header{
    position: fixed;
    width: 100%;
    height: 80px;
    background: #ffffff;
    border:0px solid transparent;
    border-radius: 5px;
    box-shadow: 0px 10px 20px 0px rgb(0 0 0 / 10%);
    padding: 0 ;
    z-index: 5;
}

.nav{
    width: 100%;
    max-width: 270px;
}

.logo-header{
    width: 189px;  
}

@media(max-width:540px){
    .logo-header{
        width:55px;
    }
}