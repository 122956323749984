.intro-content{
    position: relative;
    align-items: flex-start;
    padding: 170px 0 0;
    &_case{
        align-items: flex-start !important;
    }

    &_title{
        max-width: 634px;
        font-size: 42px;
        line-height: 1.4;
        font-weight: 800;
        color: #000000;
        margin: 100px 0 50px;
    }

    &_info{
        max-width: 568px;
        font-size: 20px;
        line-height: 1.55;
        font-weight: 400;
        color: #857889;
        margin: 0 0 30px;
    }

    &_img-content{
        position: absolute;
        top: 245px;
        right: -50px;
    }
}

.bg-top{
    position: absolute;
    width: 100%;
    height: 980px;
    transform: translateY(10px);
    background-image: url(./img/Path_fon_silver.svg);
    background-size: cover;
}

@media(max-width:1170px){
    .intro-content{
        &_case{
            
            margin: 0 !important;
        }
    }

    .intro-content_title{
        font-size: 36px;
    }

    .intro-content_info{
        max-width: 500px;
        font-size: 16px;
    }

    .intro-content_img-content{
        width: 465px;
        right: 10px;
        top: 285px;
    }
}

@media (max-width:1000px) {

    .intro-content{
        padding: 50px 0 0;
    }

    .intro-content_case{
        margin: 0 auto;
    }

    .intro-content_info{
        max-width: 350px;
        font-size: 16px;
    }
    .intro-content_img-content{
        width: 465px;
        right: 10px;
        top: auto;
        bottom: -120px;
    }
}

@media (max-width:800px) {
    .intro-content_img-content{
        width: 365px;
        bottom: -190px;
    }
}

@media (max-width:620px) {
    .intro-content{
        flex-direction: column !important;

        &_title{
            font-size: 28px;
        }

        &_img-content{
            width: 345px;
            position: relative;
            bottom: auto;
            margin: 100px 0 0;
        }
    }
}