body{
    margin: 0;
    padding: 0;
}

#wibsite-body{
    overflow: hidden;
}

a{
    display: flex;
    justify-content: space-between;
    width: 190px;
    color: #ff6d0c;
    font-size: 14px;
    font-family: 'ProximaNova',Arial,sans-serif;
    line-height: 1.4;
    font-weight: 400;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
    text-decoration: none;
    border-bottom:1px solid transparent;
    transition: all 1s;
    &:hover{
        transform: scale(1.01);
        border-bottom:1px solid #ff6d0c;
    }
}

span,button,h1,h2,h3,h4,h5, p, input, textarea{
    font-family: 'Montserrat', sans-serif;
}

.case{
    width: 100%;
    max-width: 1160px;
    margin: 0 auto;
}

.dash{
    width: 30px;
    height: 5px;
    border-radius: 75px;
    background-color: #ff6d0c;
    margin: 0 0 40px;
}


.d-flex{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.d-flex_column{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.flex-jc-sb{
    justify-content: space-between;
}

h1{
    font-size: 34px;
    color: #000000;
    line-height: 1.3;
    font-weight: 800;
}

h2{
    line-height: 1.55;
    font-weight: 700;
    font-size: 20px;
    color: #4e3057;
}

h3{
    color: #000000;
    font-size: 18px;
    line-height: 1.55;
    font-weight: 700;
    margin-top: 0;
}

h4{
    color: #000000;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 700;
}

h5{
    font-size: 16px;
    line-height: 1.55;
    font-weight: 400;
    color: #857889;
    margin-top: 0;
}

@media (max-width:1170px) {
    .case{
        margin: 0 10px;
    }
}

