.cross-button{
    position: absolute;
    top: 20px;
    right: 20px;
    width: 35px;
    height: 32px;
    cursor: pointer;
    &:hover{
        animation: puls 2s infinite;
    }
    
    &_top{
        position: relative;
        top: 50%;
        left: -4px;
        width: 41px;
        height: 2px;
        transform: rotate(45deg);
        background: #ff6d0c;
    }

    &_botton{
        position: relative;
        top: 43%;
        left: -4px;
        width: 41px;
        height: 2px;
        transform: rotate(-45deg);
        background: #ff6d0c;
    }
}

@keyframes puls{
    0%{
        transform: scale(1);
    }

    50%{
        transform: scale(1.2);
    }

    100%{
        transform: scale(1);
    }
}