
.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.form-case{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 52px auto 0;
    &>img{
        width: 215px;
    }
    &>h3{
        font-weight: 400;
    }
}

.case-input{
    position: relative;
    width: 80% !important;
    color: #4e3057;
    font-size: 16px;
    font-weight: 400;
    padding-bottom: 5px;
    &>label{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0 auto;
        &>input{
            border: 2px solid transparent;
            border-bottom:2px solid #ff6d0c;
            padding: 10px 0;
            outline: none;
            background-color: transparent;
            &::placeholder{
                font-family: 'ProximaNova',Arial,sans-serif;
                font-size: 16px;
                color: #808080ea;
                
            }
        }
    }
}

.error{
    position: relative;
    bottom: -5px;
    z-index: 20;
    filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.39));

    &>div{
        position:absolute;
        width: 10px;
        height: 10px;
        background-color: #fff;
        transform: rotate(45deg) translate(12px, -16px);
        z-index: 2;
    }

    &>span{
        position: absolute;
        left: 0px;
        padding: 5px 10px;
        background-color: #fff;
        color: #f12626;
    }
}

.error-input{
    border:1.5px #f12626 solid !important;
}

.errCap{
    border:2px solid #f12626 !important
}

.cap{
    width: 303;
    height: 83px;
    border:2px solid transparent
}

.text-aria{
    width: 100%;
    &>label>textarea{
        display: block;
        width: 100%;
        height: 73px;
        margin: 0 auto;
        border: 0px solid transparent;
        border-bottom: 2px solid #ff6d0c;
        padding: 10px 0 0;
        outline: none;
        background-color: transparent;
        resize: none;
        &::placeholder{
            font-family: 'ProximaNova',Arial,sans-serif;
            font-size: 16px;
            color: #808080ea;
            
        }
    }
}


.button-form{
    width: 150px;
    height: 50px;
    border: 2px solid #ff6d0c;
    border-radius: 75px;
    background: #ff6d0c;
    font-size: 16px;
    line-height: 1.55;
    font-weight: 600;
    color: #ffffff;
    cursor: pointer;
    transition: all ease-in-out 0.2s;
    &:hover{
        color: #ff6d0c;
        background:#ffffff;
        animation: puls 1s infinite;
    }
}

@keyframes puls{
    0%{
        transform: scale(1);
    }

    50%{
        transform: scale(1.01);
    }

    100%{
        transform: scale(1);
    }
}
