.footer{
    display: flex;
    justify-content: center;
    padding: 50px 0 20px;
    background-color: #f6f4f6;
    border-top:4px solid #4e3057;
    &>span{
        font-size: 16px;
        line-height: 1.55;
        font-weight: 400;
        color: #857889b2;
    }
}