.pop-up-modail{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #857889d7;
    z-index: 20;
    opacity: 1;
    transition: all 1s;
    &_off{
        display: none;
        opacity: 0;
    }
}

.form-size{
    position: relative;
    width: 545px;
    height: 671px;
    background: #f6f4f6;
    border-radius: 5px;
}

.form-img{
    position: absolute;
    bottom: -87.3px;
    left: 5px;
    width: 118px;
}

.ans-pop-up{
    position:fixed;
    bottom: 5px;
    right: 5px;
    background: #ff6d0c;
    transition: all 2s;
    opacity: 1;
    z-index: 20;
    &>span{
        display: flex;
        max-width: 250px;
        text-align: center;
        color:#ffffff;
        padding: 10px 20px;
    }
    &>div{
        position: absolute;
        right: 5px;
        top: 5px;
    }
    &__dis{
        display: none;
        opacity: 0;
    }
}

.little-cross{
    width: 10px;
    height: 10px;
    cursor: pointer;
    &_top{
        width: 11px;
        height: 1px;
        transform: rotate(45deg) translate(3px, 3px);
        background: #ffffff;
    }
    &_bottom{
        transform:rotate(-45deg) translate(-3px, 2px);
        width: 11px;
        height: 1px;
        background: #ffffff;
    }
}

@media(max-width:1000px){
    .form-img{
        position: absolute;
        bottom: -50.3px;
        left: 5px;
        width: 70px;
    }
}

