.button-header{
    width: 170px;
    height: 50px;
    border: 2px solid #ff6d0c;
    border-radius: 75px;
    background: transparent;
    font-size:16px;
    line-height: 1.55;
    font-weight: 600;
    color: #4e3057;
    cursor: pointer;
    transition: all ease-in-out 0.2s;
    &:hover{
        color: #ffffff;
        background:#ff6d0c;
        animation: puls 1s infinite;
    }
}

@keyframes puls{
    0%{
        transform: scale(1);
    }

    50%{
        transform: scale(1.01);
    }

    100%{
        transform: scale(1);
    }
}

.button-content{
    width: 230px;
    height: 60px;
    border: 2px solid #ff6d0c;
    border-radius: 75px;
    background: #ff6d0c;
    font-size:16px;
    line-height: 1.55;
    font-weight: 600;
    color: #ffffff;
    cursor: pointer;
    transition: all ease-in-out 0.2s;
    &:hover{
        color: #4e3057;
        background:#ffffff;
        animation: puls 1s infinite;
    }
}