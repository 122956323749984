.info-case{
    display: flex;
    justify-content: space-between;
    align-items: flex-start !important;
    &_info{
        display: grid; 
        grid-template-columns: 1fr 1fr; 
        grid-template-rows: 1fr 1fr; 
        gap: 40px 40px; 
        max-width: 800px;
    }
}
.info-case-logo{
    height: 356px;
}

.box{
    max-width: 350px;
    &_img{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
        border-radius: 20px;
        background-color: #ffffff;
        margin-bottom: 30px;
    }
}

@media(max-width:1020px){
    .info-case-logo{
        position:absolute ;
        left: 0;
    }
    .info-case{
        flex-direction: column;
        justify-content: center;
        &_info{
            grid-template-columns: 0.9fr 1.1fr; 
            grid-template-rows: 0.7fr 0.7fr 0.7fr; 
            grid-template-areas: 
            ". box0"
            ". box1"
            "box3 box2"; 
        }
    }
    .box0 { grid-area: box0; }
    .box1 { grid-area: box1; }
    .box2 { grid-area: box2; }
    .box3 { grid-area: box3; }
}

@media(max-width:750px){
    .info-case-logo{
        position: relative;
        width:209px ;
        height: auto;
    }

    .info-case{
        flex-direction: row-reverse !important;
        justify-content: center;
        &_info{
            display: grid; 
            grid-template-columns: 1fr; 
            grid-template-rows: 0.9fr 0.9fr 0.9fr 0.9fr; 
            gap: 11px 20px; 
            grid-template-areas: 
                "box0"
                "box1"
                "box2"
                "box3"; 
        }
    }
    .box0 { grid-area: box0; }
    .box1 { grid-area: box1; }
    .box2 { grid-area: box2; }
    .box3 { grid-area: box3; }
}

@media(max-width:700px){
    .info-case{
        flex-direction: column-reverse !important;
        align-items:center !important;
        &_info{
            &>div{
                max-width: 350px;
            }
        }
    }
    .info-case-logo{
        left: auto;
        width:266px;
    }
}