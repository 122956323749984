.why-lmachine{
    position: relative;
    margin-top: 200px !important;

    &>h1,h2{
        text-align: center;
    }

    &>h1{
        max-width: 1025px;
    }
}

.bg-botton{
    position: absolute;
    width: 100%;
    height: 1173px;
    transform: rotate(180deg) translateY(10px);
    background-image: url(./img/Path_fon_silver.svg);
    background-size: cover;
}

@media (max-width:1020px) {
    .bg-botton{
        height: 1373px;
    }
}

@media (max-width:850px) {
    .why-lmachine{
        margin-top:0 !important;
        .bg-botton{
            height: 1453px;
        }
    }
}

@media (max-width:700px) {
    .bg-botton{
        height: 1833px;
    }
}