.leng-body{
    position: relative;
    max-height: 50px;
    border-radius: 70px;
    border: 2px solid #ff6d0c;
    overflow: hidden;
    transition: all 0.5s;
    background: #ffffff;
    top: 0px;
    &:hover{
        top: 25px;
        max-height: 100px;
    }
}

.leng-case{
    position: relative;
    width: 50px;
}

.nav-button{
    position: relative;
    width: 50px;
    height: 50px;
    font-size: 16px;
    line-height: 1.55;
    font-weight: 600;
    color: #4e3057;
    border: 0px transparent;
    background: transparent;
    cursor: pointer;
    transition: all 0.5s;
    

    &:hover{
        background-color: #ff6d0c;
        color: #ffffff;
    }
}