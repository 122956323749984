.text-box{
    max-width: 604px;
    &>h1{
        max-width: 604px;
    }
}

.easy-and-funct{
    position: relative;
    justify-content: flex-end;
    margin-top: 100px !important;

    &_img-content{
        position: absolute;
        left: -40px;
        width: 569px;
    }
}

@media (max-width:1260px){
    .easy-and-funct_img-content{
        position: relative;
        width: 409px;
        bottom: -230px;
    }
}

@media(max-width:980px){
    .easy-and-funct{
        flex-direction: column-reverse !important;
    }
    .easy-and-funct_img-content{
        margin: 0 auto;
        left: 0px;
        bottom: 0px;
    }

}