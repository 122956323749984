.carouse{
    display: flex;
    max-width: 1080px;
    margin: 280px auto 100px;
    &_item{
        display: flex !important;
        align-items: center;
        justify-content: center;
        height: 161px;
    }
}

.slick-slide{
    min-width: 175px;
}

.logo-compani{
    max-width: 170px;
    max-height: 79px;
}

.slick-prev:before{
    content: url(./img/Path_98.svg);
}

.slick-next {
    transform: rotate(180deg);
    &:before{
        content: url(./img/Path_98.svg);
    }
}

@media (max-width:1170px) {
    .slick-next{
        right:10px
    }

    .slick-prev{
        left: 10px;
    }
}

@media (max-width:620px) {
    .carouse{
        margin: 60px auto 100px;
    }   
}