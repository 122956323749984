.for-dev{
    position: relative;
    margin-top: 100px !important;

    &_img-content{
        position: absolute;
        top: 210px;
        right: 0;
        width: 569px;
        height: 609px;
    }
}

.text-box{
    position: relative;
}

@media(max-width:1160px){
    .for-dev_img-content{
        position: relative;
        width: 425px;
    }
}

@media(max-width:1110px){
    .for-dev{
        flex-direction: column !important;
    }
    .for-dev_img-content{
        width: 620px;
        padding: 40px;
        top: 0;
    }
}

@media(max-width:600px){

    .for-dev_img-content{
        width: 420px;
        padding: 0px;
    }
}