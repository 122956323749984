.box-info{
    align-items: flex-start !important;
    justify-content: flex-start !important;
    max-width: 230px;
    height: 330px;
    background-color: #f6f4f6;
    border-radius: 20px;
    padding: 25px;
    margin: 0 !important;

    &_img{
        display: flex;
        align-content: center;
        align-items: center;    
        width: 100px;
        height: 100px;
        background-color: #ffffff;
        border-radius: 30px;        
    }

    &>h3{
        width: 225px;
    }
    &_text1 {
        margin: 20px 0 0;
    }
}

.case-box-info{
    justify-content: space-between;
    align-items: flex-start;
    max-width: 580px;
    margin-top: 30px;
}

@media(max-width:600px){
    .case-box-info{
        flex-direction: column !important;
    }

    .box-info{
        margin: 0 0 20px !important;
    }
}