.easy-icon{
    position: relative;
    align-items: flex-start !important;
    
    &_img{
        position: relative;
        top: 0;
        width: 60px;
        height: 60px;
        background: rgba(78, 48, 87, 0.062745098);
        border-radius: 15px;
        margin: 0 30px 0 0 !important;
    }

    &_text{
        width: 100%;
        max-width: 500px;
    }
}

.icon-box{
    margin-top: 40px;
}

@media(max-width:1080px){
    .icon-box{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin: 40px 20px 0 0;
    }

    .easy-icon{
        max-width: 550px;
        justify-content: flex-end;
    }
}
@media(max-width:980px){
    .icon-box{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin: 40px 20px 0 0;
    }

    .easy-icon{
        max-width: 550px;
        justify-content: flex-end;
    }
}