.content{
    &_title{
        font-size: 42px;
        font-family: 'ProximaNova',Arial,sans-serif;
        line-height: 1.55;
        font-weight: 800;
        color: #000000;
        margin: 0 0 20px;
    }
}

@media (max-width:620px){
    .content{
        &_title{
            font-size: 36px;
        }
    }
}


@media (max-width:470px){
    .content{
        &_title{
            font-size: 26px;
        }
    }
}